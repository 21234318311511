/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ContentPageLayout } from "@components/Layout";
import { Form, ReadOnlyForm } from "@components/Form";
import { ShortcutBox } from "@components/BlueBox";
import { Hr, Box, Text } from "@components/Primitives";
import { TextSection } from "@components/TextSection";
import styled from "styled-components";
import { MY_INFORMATION } from "@constants";
import { useStores } from "@stores";
import { getNotificationValues } from "@utils";
import { FileUploader } from "@components/FileUploader";
import { FormattedMessage as M } from "gatsby-plugin-intl";
import { updateProfilePic } from "../../services/api";
import { Field } from "react-final-form";
import { FormRow } from "@components/FormRow";
import { until } from "../../styles/theme-utils";
import { NaviButtons } from "@components/NaviButtons";
import { Checkbox } from "@components/Checkbox";
import { PAGE_NAV_ITEMS } from "../../constants/page-nav-items";

interface MyInformationPaymentInformationPageProps {
  data: IMarkdownData;
  location: any;
}

const GeneralInformationForm = styled(Form)`
  .input-wrapper {
    &.field_phone_number {
      margin-bottom: 3rem;
    }
  }
`;

const ProfilePicUploader = styled(FileUploader)`
  label {
    justify-content: flex-end;
    ${until("lg", `justify-content: flex-start`)};
  }
`;

const ProfilePicRow = styled(FormRow)`
  margin-top: 3rem;
  ${until("md", `margin-top: 2rem`)};
`;

const NewsletterRow = styled(FormRow)`
  align-items: flex-start;
`;

const MyInformationPaymentInformationPage: FC<MyInformationPaymentInformationPageProps> =
  observer(() => {
    const {
      currentUserStore: {
        currentUser,
        state: currentUserStoreState,
        updateCurrentUser,
        profilePicUrl,
      },
      agreementStore: {
        getAgreementParties,
        selectedAgreementId,
        state: agreementStoreState,
        currentAgreementParties,
      },
    }: {
      currentUserStore: ICurrentUserStore;
      agreementStore: IAgreementStore;
    } = useStores();

    const [selectedPic, setSelectedPic] = useState(null as File | null);

    const onGeneralSubmit = (
      _changedValues: any,
      callback: Function,
      formValues: any
    ) => {
      const picked = (({
        phone_number,
        language,
        newsletter,
        notifications,
        email,
      }) => ({ phone_number, email, language, newsletter, notifications }))(
        formValues
      );
      updateCurrentUser(picked, callback);
      if (selectedPic) {
        updateProfilePic(selectedPic);
      }
    };

    const onNotificationsSubmit = (
      _changedValues: any,
      callback: Function,
      formValues: any
    ) => {
      const picked = (({ phone_number, language, newsletter }) => ({
        phone_number,
        language,
        newsletter,
      }))(formValues);
      updateCurrentUser(
        {
          ...picked,
          ...{ notifications: getNotificationValues(formValues, "toYh") },
        },
        callback
      );
    };

    const onImageChange = (e: any) => {
      const file = e.target.files[0];
      setSelectedPic(file);
    };

    const customRenderer = (field: any) => {
      switch (field.id) {
        case "profilePic":
          return (
            <ProfilePicRow
              id="profilePic"
              component={
                <Field name="profilePic">
                  {(props) => (
                    <ProfilePicUploader
                      id="upload-profile-picture"
                      onInputChange={onImageChange}
                      buttonLabel={<M id="fields.label.uploadNewProfilePic" />}
                      image
                      imageSize={50}
                      onChange={props.input.onChange}
                      imageUrl={profilePicUrl}
                    />
                  )}
                </Field>
              }
            />
          );
        case "newsletter":
          return (
            <NewsletterRow
              id="newsletter"
              component={
                <>
                  <Field name="newsletter" type="checkbox">
                    {(props) => (
                      // @ts-ignore
                      <Checkbox
                        label="subscribingToNewsletter"
                        id="newsletter"
                        {...props.input}
                      />
                    )}
                  </Field>
                  <Text color="black">
                    <M id="pages.myInformation.newsletterDescription" />
                  </Text>
                </>
              }
            />
          );
        default:
          return null;
      }
    };

    const isLoading =
      currentUserStoreState === "Loading" || agreementStoreState === "Loading";
    const primaryParty = currentAgreementParties?.find(
      (party) => party.current_party
    );

    const combinedData = { ...primaryParty, ...currentUser };

    useEffect(() => {
      if (typeof window === "undefined") return;
      if (selectedAgreementId && !currentAgreementParties)
        getAgreementParties(selectedAgreementId);
    }, [currentUser]);

    return (
      <ContentPageLayout wideContent>
        <TextSection
          title={<M id="fields.label.myInformation.title" />}
          text={<M id="fields.label.myInformation.text" />}
          afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.index} />}
        />
        <Box>
          <GeneralInformationForm
            id="general-information"
            fields={MY_INFORMATION.general}
            values={combinedData}
            loading={isLoading}
            onSubmit={onGeneralSubmit}
            customRenderer={customRenderer}
          />
        </Box>
        <Hr />

        <TextSection
          title={<M id="fields.label.contactPermissions.title" />}
          text={<M id="fields.label.contactPermissions.text" />}
        />
        <Form
          id="contact-permissions"
          fields={MY_INFORMATION.notifications}
          values={{
            ...combinedData,
            ...{
              notifications: getNotificationValues(combinedData, "fromYh"),
            },
          }}
          loading={isLoading}
          onSubmit={onNotificationsSubmit}
          customRenderer={customRenderer}
        />
        <Hr />

        <TextSection
          title={<M id="fields.label.personalInformation.title" />}
          text={<M id="fields.label.personalInformation.text" />}
        />
        <ReadOnlyForm
          id="personal-information"
          fields={MY_INFORMATION.personalInformation}
          values={combinedData}
          loading={isLoading}
        />
        <ShortcutBox />
      </ContentPageLayout>
    );
  });

export default MyInformationPaymentInformationPage;
