import React, { ReactNode } from 'react';
import { Box, Heading, Spinner } from '@components/Primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Color } from '../../types';

interface Props {
  title?: string | ReactNode;
  children: ReactNode;
  mt?: string;
  mb?: string;
  translate?: boolean;
  color?: Color;
  loading?: boolean;
  alignLeft?: boolean;
}

const Container = styled(Box)`
  text-align: center;
  position: relative;

  .spinner {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: calc(50% - 0.75rem);
  }
`;

const BlueBox: React.FC<Props> = ({
  children, title, translate, color, loading, alignLeft = false, ...rest
}) => (
  <Container color={color || 'lightBlue'} p="2" {...rest} style={{textAlign: alignLeft ? 'left' : 'center'}}>
    {loading && <Spinner color="mainBlue" />}
    {!loading && (
      <>
        {title && (
          <Heading mb="1" level="3">
            {translate && typeof title === 'string' && <M id={title} />}
            {!translate && title}
          </Heading>
        )}
        {children}
      </>
    )}
  </Container>
);


export { BlueBox };
