import React, { ReactNode } from 'react';
import { Link, Text } from '@components/Primitives';
import { BlueBox } from '@components/BlueBox';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { NAV_ITEMS } from '../../../constants/nav-items';

interface Props {
  children?: ReactNode;
  items?: { name: string; href: string }[];
}

const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: auto;
  > a {
    margin: 1rem;
  }
`;

const ShortcutBox: React.FC<Props> = ({ items }) => {
  return (
    <BlueBox mt="3" title="common.content.shortcutBox.shortcuts" translate>
      <LinkContainer>
        {(items || NAV_ITEMS).map((item: { name: string; href: string }) => (
          <Link key={item.name} to={item.href}>
            <Text underline>
              <M id={item.name} />
            </Text>
          </Link>
        ))}
      </LinkContainer>
    </BlueBox>
  );
};

export { ShortcutBox };
